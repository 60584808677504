<template>
  <div class="auth-wrapper auth-v2">
    <b-row :style="loginStyle" class="auth-inner m-0 justify-content-center">
      <b-col lg="10" class="pt-5">
          <InitialHeader :onlyLogo="true" />
      </b-col>
      <b-col
          lg="5"
          class="d-flex align-items-center mb-5"
      >
        <b-col
            sm="8"
            md="6"
            lg="9"
            class="px-xl-3 mx-auto auth-bg py-5 my-5 rounded"
        >
          <h2
              class="mb-1 auth-title"
          >
            Verifique seu e-mail
          </h2>
          <b-card-text class="mb-2">
            Acabamos de enviar uma mensagem para sua conta de e-mail: <strong>{{ emailSent }}</strong> Siga os passos descritos lá para finalizar seu cadastro.
          </b-card-text>
          <b-card-text class="mb-2">
            Não recebeu um e-mail? Verifique também a sua caixa de spam ou
            <b-link
              @click="actionResendEmail">
              reenvie!
            </b-link>
          </b-card-text>
        </b-col>
      </b-col>
      <FooterInitial/>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BIconCheckCircle, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, BIconChevronLeft,
} from 'bootstrap-vue';
import LoginImage from "@/views/pages/authentication/components/LoginImage";
import FooterInitial from '@/views/components/custom/footer/FooterInitial.vue';
import InitialHeader from '@/views/components/custom/page-header/InitialHeader.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BIconCheckCircle,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    BIconChevronLeft,
    LoginImage,
    FooterInitial,
    InitialHeader
},

  props: ['email'],

  mounted() {
    this.$helpers.indexablePage();
  },

  data() {
    return {
      emailSent: this.email,  
      sideImg: require('@/assets/images/login/login-vacina-sesi-logo.jpg'),
      loginStyle:{
          'background-image': `url(${require('@/assets/custom-images/bg/bg-login.png')})`,
          'background-repeat': 'no-repeat',
          'background-position': 'center center',
          'background-size': 'cover'
        },
    }
  },

  methods: {
    actionResendEmail() {
      if(this.emailSent) {
        const params = {
          email: this.emailSent
        }

        this.$http.post(this.$api.resendEmail(), params).then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Novo E-mail enviado!',
              icon: 'MailIcon',
              text: 'Um novo E-mail foi enviado para sua conta de E-mail.',
              variant: 'success',
            },
          },
          {
            timeout: 8000,
          })
        });

        return;
      }

      this.$router.push({	name: 'auth-forgot-password' });
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>